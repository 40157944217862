@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap");

$color-white: #fff;
$color-black: #000;
$color-red: #ae2939;
$color-green: #21ba45;
$color-blue: #024c8b;

$color-gray: #e0e1e2;
$color-gray-f2: #f2f2f2;
$color-gray-d9: #d9d9d9;
$color-gray-f6: #fff6f6;

#ds160-photo-tool-root {
  margin: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
}

#ds160-photo-tool-root > * {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.ds160-photo-tool-root {
  h1 {
    color: $color-blue;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
  }

  .photo-tool-container {
    width: 100%;
    background: $color-gray-f2;
    border: 1px solid $color-gray-d9;
    padding: 40px;
  }

  .sk-photo-examples {
    display: flex;
    flex-direction: column;
    margin: 32px 0 64px;
    gap: 32px;

    @media screen and (min-width: 720px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }

    &__example {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 16px;
        text-align: center;
      }

      &__photos {
        display: flex;
        gap: 32px;
      }
    }
  }

  .sk-photo-example {
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
  }

  .sk-photo-with-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sk-icon {
    width: 30px;
    height: 30px;

    &--red {
      fill: $color-red;
    }

    &--green {
      fill: $color-green;
    }
  }

  .sk-portrait-image {
    width: 280px;
    height: 280px;

    @media screen and (min-width: 768px) {
      width: 400px;
      height: 400px;
    }
  }

  .sk-cropper {
    position: relative;
    width: 100%;
  }

  .sk-upload-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;
    gap: 16px;
  }

  .sk-button {
    font-family: inherit;
    width: max-content;
    padding: 8px;
    font-size: 16px;
    line-height: 28px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;

    &--big {
      padding: 12px 24px 12px;
      text-transform: uppercase;
      font-weight: 700;
    }

    &--silver {
      color: $color-black;
      background-color: $color-gray;
    }

    &--red {
      background-color: $color-red;
      color: $color-white;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .sk-actions {
    display: flex;
    justify-content: center;
    gap: 16px;

    & > * {
      min-width: 140px;
    }
  }

  .sk-loading {
    opacity: 0.5;
  }

  .sk-error-msg {
    padding: 24px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 17px;
    background-color: $color-gray-f6;
    color: $color-red;
    border: 1px solid $color-red;
    border-radius: 4px;
  }
}
